<template>
  <v-select
    label="text"
    :options="vacunas"
    :reduce="(vacuna) => (mode === 'object' ? vacuna : vacuna.id)"
    @search="debounceSearch"
    v-model="selected"
    v-on:input="onBlur"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events" />
    </template>
  </v-select>
</template>

<script>
import {debounce} from 'lodash';
import vacunaService from "../../services/vacunaService";
import VSelect from 'vue-select';
export default {
  props: ["value", "mode", "validator", "id_sede_origen"],
  components:{VSelect},
  data() {
    return {
      
      vacunas: [],
      selected: this.value,
      search: "",
    };
  },
  created() {
    this.debounceSearch = debounce(this.buscarVacunas, 300);
  },
  methods: {
    async buscarVacunas(search, loading) {
            try {
               this.vacunas = [];

                if (search === "") {
                    return;
                }
                loading(true);

                let response = await vacunaService.search_by_sede(search,this.id_sede_origen);
                this.vacunas = response.data.results;

                loading(false);
            } catch (e) {
                loading(false);
                alert("Ocurrio un error, por favor intente nuevamente");
                console.error(e);
            }
        },
   
        onBlur(value){
        this.$emit('input', value);
        }
  },
  computed: {
    classObject: function () {
      if (this.validator === undefined || this.validator === null) {
        return "";
      }

      return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
    }
  },
};
</script>
<style scoped>
</style>
